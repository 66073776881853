import { Link, useLocation, useNavigate } from 'react-router-dom';

import appConfig from '@app/configs/appConfig.json';
import { DISCORD, X } from '@app/constants/socialLinks';

import { LinkButton } from '@app/components/common/LinkButton';

import discordIcon from '@static/images/socials/discord.svg';
import logo from '@static/images/logo.svg';
import xIcon from '@static/images/socials/x.svg';

import './index.scss';

export class ImageItem {
    constructor(
        public src: string = '',
        public link: string = '',
        public alt: string = '',
    ) { }
};

const HEADER_INDENT = 125;

export const Footer: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleAnchorLinkClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
        targetId: string,
    ) => {
        event.preventDefault();

        if (location.pathname !== '/') {
            navigate('/');

            setTimeout(() => {
                const element = document.getElementById(targetId);
                if (element) {
                    window.scrollTo({
                        top: element.getBoundingClientRect().top -
                            document.body.getBoundingClientRect().top - HEADER_INDENT,
                        behavior: 'smooth',
                    });
                }
            }, appConfig.numbers.THREE_HUNDRED_NUMBER);
        } else {
            const element = document.getElementById(targetId);
            if (element) {
                window.scrollTo({
                    top: element.getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top - HEADER_INDENT,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <div className="footer__main">
                    <div className="footer__main__about">
                        <div>
                            <div className="footer__main__top-side">
                                <a href="/">
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className="footer__logo"
                                        loading="lazy"
                                    />
                                </a>
                                <div className="footer__socials__mobile">
                                    <LinkButton
                                        link={X}
                                        isTargetBlank={true}
                                        image={xIcon}
                                        variant="transparent"
                                    />
                                    <LinkButton
                                        link={DISCORD}
                                        isTargetBlank={true}
                                        image={discordIcon}
                                        variant="transparent"
                                    />
                                </div>
                            </div>
                            <div className="footer__description">
                                Cross-chain bridge for EVM and Non-EVM
                                blockchains
                            </div>
                            <div className="footer__buttons__mobile">
                                <LinkButton
                                    label="launch bridge"
                                    link="https://bridge.tricorn.network/"
                                    isTargetBlank={true}
                                />
                                <LinkButton
                                    label="launch lending"
                                    isComingSoon={true}
                                />
                            </div>
                        </div>
                        <div className="footer__links">
                            <div className="footer__links__list">
                                <a
                                    className="footer__links__list__item"
                                    aria-label="product"
                                >
                                    Product
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="bridge app"
                                    href="https://bridge.tricorn.network/"
                                    target="_blank"
                                >
                                    Bridge App
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="add yor blockchain"
                                    href="mailto:contact@tricorn.network?subject=New network integration to Tricorn"
                                >
                                    Add your blockchain
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="add token"
                                    href="mailto:contact@tricorn.network?subject=New token integration to Tricorn"
                                >
                                    Add Token
                                </a>
                            </div>
                            <div className="footer__links__list">
                                <a
                                    className="footer__links__list__item"
                                    aria-label="company"
                                >
                                    Company
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="about"
                                    onClick={(e) =>
                                        handleAnchorLinkClick(e, 'about')
                                    }
                                >
                                    About
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="solutions"
                                    onClick={(e) =>
                                        handleAnchorLinkClick(e, 'solutions')
                                    }
                                >
                                    Solutions
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="vision"
                                    onClick={(e) => handleAnchorLinkClick(e, 'vision')}
                                >
                                    Vision
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="chains"
                                    onClick={(e) => handleAnchorLinkClick(e, 'chains')}
                                >
                                    Chains
                                </a>
                                <a
                                    className="footer__links__list__item"
                                    aria-label="roadmap"
                                    onClick={(e) => handleAnchorLinkClick(e, 'roadmap')}
                                >
                                    Roadmap
                                </a>
                            </div>
                            <div className="footer__links__list">
                                <a
                                    className="footer__links__list__item"
                                    aria-label="help"
                                >
                                    Help
                                </a>
                                <Link
                                    onClick={() => { scrollTo(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.ZERO_NUMBER,); }}
                                    to="/how-to-use"
                                    className="footer__links__list__item"
                                    aria-label="documentation"
                                >
                                    How To Use
                                </Link>
                                <Link
                                    onClick={() => { scrollTo(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.ZERO_NUMBER,); }}
                                    to="/faq"
                                    className="footer__links__list__item"
                                    aria-label="FAQ"
                                >
                                    FAQ
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer__socials">
                        <LinkButton
                            link={X}
                            isTargetBlank={true}
                            image={xIcon}
                            variant="transparent"
                        />
                        <LinkButton
                            link={DISCORD}
                            isTargetBlank={true}
                            image={discordIcon}
                            variant="transparent"
                        />
                    </div>
                </div>
                <div className="footer__right-side">
                    <div className="footer__buttons">
                        <LinkButton
                            label="launch bridge"
                            link="https://bridge.tricorn.network/"
                            isTargetBlank={true}
                        />
                        <LinkButton
                            label="launch lending"
                            isComingSoon={true}
                        />
                    </div>
                    <div className="footer__helpers">
                        <a className="footer__helpers__link" aria-label="Terms" href="#">
                            Terms of Use
                        </a>
                        <a className="footer__helpers__link" aria-label="Privacy Policy" href="#">
                            Privacy Policy
                        </a>
                        <a className="footer__helpers__link" aria-label="Cookie Policy" href="#">
                            Cookie Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
