import { LinkButton } from '@app/components/common/LinkButton';

import './index.scss';

const NotFound = () =>
    <div className="not-found">
        <p className="not-found__error">404</p>
        <div>
            <h1 className="not-found__title">Page Not Found</h1>
            <p className="not-found__description">We couldn't find the page you're looking for. But don't worry, you’re still in the right place.</p>
        </div>
        <LinkButton
            label="Back to Home"
            link="/"
        />
    </div>;

export default NotFound;
