import { LinkButton } from '@app/components/common/LinkButton';
import { DISCORD, X } from '@app/constants/socialLinks';

import logo from '@static/images/logo.svg';
import logoMobile from '@static/images/logo-mobile.svg';
import xIcon from '@static/images/socials/x.svg';
import discordIcon from '@static/images/socials/discord.svg';

import './index.scss';

export const Navbar: React.FC = () =>
    <nav className="navbar">
        <div className="navbar__bg" />
        <div className="navbar__wrapper">
            <div className="navbar__left-side">
                <a
                    className="navbar__logo"
                    href="/"
                    aria-label="navbar logo"
                >
                    <img
                        className="navbar__logo__image"
                        alt="logo"
                        src={logo}
                    />
                    <img
                        className="navbar__logo__image__mobile"
                        alt="logo mobile"
                        src={logoMobile}
                    />
                </a>
                <div className="navbar__links">
                    <LinkButton
                        link={X}
                        isTargetBlank={true}
                        image={xIcon}
                        variant="transparent"
                    />
                    <LinkButton
                        link={DISCORD}
                        isTargetBlank={true}
                        image={discordIcon}
                        variant="transparent"
                    />
                </div>
            </div>
            <div className="navbar__buttons">
                <LinkButton
                    label="launch bridge"
                    link="https://bridge.tricorn.network/"
                    isTargetBlank={true}
                />
                <LinkButton
                    label="launch lending"
                    isComingSoon={true}
                />
            </div>
        </div>
    </nav>;
