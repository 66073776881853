export const Bridge: React.FC = () =>
    <svg width="149" height="150" viewBox="0 0 149 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M105.958 123.456C108.132 122.189 111.646 122.189 113.807 123.456C115.981 124.723 115.981 126.761 113.807 128.028C111.632 129.295 108.118 129.295 105.958 128.028C103.783 126.761 103.783 124.723 105.958 123.456Z" fill="#1A8E48" />
        <path d="M66.521 99.1762C68.6952 97.9092 72.2095 97.9092 74.3701 99.1762C76.5443 100.443 76.5443 102.481 74.3701 103.748C72.1958 105.015 68.6816 105.015 66.521 103.748C64.3468 102.481 64.3468 100.443 66.521 99.1762Z" fill="#1A8E48" />
        <path d="M70.4456 100.278C72.0318 100.278 73.3172 101.572 73.3172 103.17V113.389C73.3172 118.443 76.9955 124.86 81.3439 127.381L101.869 139.307C103.428 140.202 104.85 140.45 105.684 139.968C106.518 139.486 107.024 138.109 107.024 136.305V126.086C107.024 124.489 108.31 123.194 109.896 123.194C111.482 123.194 112.767 124.489 112.767 126.086V136.305C112.767 140.257 111.236 143.425 108.569 144.967C105.903 146.523 102.416 146.275 99.0111 144.306L78.486 132.38C72.3736 128.827 67.5876 120.481 67.5876 113.375V103.156C67.5876 101.559 68.873 100.264 70.4592 100.264L70.4456 100.278Z" fill="url(#paint0_linear_594_102922)" />
        <path d="M55.6775 39.2556L55.4997 86.5339C55.4997 88.0625 54.9664 89.1367 54.0776 89.6601L50.0437 92.0288C50.9051 91.5055 51.4384 90.4313 51.4658 88.9026L51.6436 41.6244C51.6436 38.5257 49.483 34.7661 46.8029 33.2099L21.2731 18.364C19.933 17.5928 18.716 17.5101 17.8545 18.0334L21.8884 15.6647C22.7635 15.1414 23.9942 15.224 25.3343 16.009L50.8504 30.8411C53.5306 32.3973 55.6911 36.157 55.6911 39.2556H55.6775Z" fill="url(#paint1_linear_594_102922)" />
        <path d="M46.8029 33.2099C49.4831 34.7661 51.6436 38.5257 51.6299 41.6244L51.4658 88.9026C51.4658 91.9875 49.2779 93.2407 46.5978 91.6845L21.0816 76.8524C18.4015 75.2962 16.2546 71.5227 16.2546 68.4379L16.4187 21.1596C16.4187 18.061 18.5929 16.8215 21.2731 18.3777L46.8029 33.2236V33.2099Z" fill="url(#paint2_linear_594_102922)" />
        <path d="M42.4956 49.1161C42.4956 55.4649 38.0652 58.0402 32.5955 54.8727C27.1258 51.6915 22.6953 43.9655 22.6953 37.6168C22.6953 31.268 27.1258 28.6927 32.5955 31.8602C38.0652 35.0415 42.4956 42.7674 42.4956 49.1161Z" fill="#1A8E48" />
        <path d="M44.9296 64.6781L20.2749 50.3418V56.002L44.9296 70.3383V64.6781Z" fill="#1A8E48" />
        <path d="M41.0461 70.7102L24.1721 60.9048V66.565L41.0461 76.3704V70.7102Z" fill="#1A8E48" />
        <path d="M94.2114 62.5021L94.0336 109.78C94.0336 111.309 93.5003 112.383 92.6115 112.907L88.5776 115.275C89.4391 114.752 89.9724 113.678 89.9997 112.149L90.1775 64.8708C90.1775 61.7722 88.017 58.0125 85.3368 56.4563L59.807 41.6104C58.4669 40.8392 57.2499 40.7566 56.3884 41.2799L60.4223 38.9112C61.2975 38.3878 62.5282 38.4705 63.8682 39.2555L89.3844 54.0876C92.0645 55.6438 94.2251 59.4035 94.2251 62.5021H94.2114Z" fill="url(#paint3_linear_594_102922)" />
        <path d="M85.3368 56.4563C88.017 58.0125 90.1775 61.7722 90.1638 64.8708L89.9998 112.149C89.9998 115.234 87.8119 116.487 85.1317 114.931L59.6019 100.085C56.9218 98.5288 54.7749 94.7554 54.7749 91.6705L54.939 44.3923C54.939 41.2937 57.1132 40.0542 59.7934 41.6104L85.3232 56.4563H85.3368Z" fill="url(#paint4_linear_594_102922)" />
        <path d="M81.0296 72.3626C81.0296 78.7114 76.5991 81.2867 71.1294 78.1192C65.6597 74.9379 61.2292 67.212 61.2292 60.8632C61.2292 54.5145 65.6597 51.9392 71.1294 55.1067C76.5991 58.2879 81.0296 66.0139 81.0296 72.3626Z" fill="#1A8E48" />
        <path d="M83.4635 87.9246L58.8088 73.5883V79.2484L83.4635 93.5848V87.9246Z" fill="#1A8E48" />
        <path d="M79.5801 93.9567L62.7061 84.1512V89.8114L79.5801 99.6169V93.9567Z" fill="#1A8E48" />
        <path d="M132.745 85.7488L132.568 133.027C132.568 134.542 132.034 135.63 131.145 136.153L127.112 138.522C127.973 137.999 128.506 136.924 128.534 135.396L128.711 88.1175C128.711 85.0189 126.551 81.2592 123.871 79.703L98.3409 64.8571C97.0008 64.0859 95.7838 64.0033 94.9224 64.5266L98.9563 62.1579C99.8314 61.6346 101.062 61.7172 102.402 62.5022L127.918 77.3343C130.598 78.8905 132.759 82.6502 132.759 85.7488H132.745Z" fill="url(#paint5_linear_594_102922)" />
        <path d="M123.871 79.6892C126.551 81.2454 128.711 85.0051 128.698 88.1037L128.534 135.382C128.534 138.467 126.346 139.72 123.666 138.164L98.1359 123.318C95.4557 121.762 93.3088 117.988 93.3088 114.903L93.4729 67.6252C93.4729 64.5266 95.6471 63.2871 98.3273 64.8433L123.857 79.6892H123.871Z" fill="url(#paint6_linear_594_102922)" />
        <path d="M119.563 95.5955C119.563 101.944 115.133 104.52 109.663 101.352C104.194 98.1708 99.7632 90.4449 99.7632 84.0962C99.7632 77.7474 104.194 75.1721 109.663 78.3396C115.133 81.5071 119.563 89.2468 119.563 95.5955Z" fill="#1A8E48" />
        <path d="M121.997 111.158L97.3428 96.8212V102.481L121.997 116.818V111.158Z" fill="#1A8E48" />
        <path d="M118.1 117.203L101.24 107.384V113.044L118.1 122.864V117.203Z" fill="#1A8E48" />
        <path d="M39.8837 26.5443C37.7095 27.8113 34.1952 27.8113 32.0347 26.5443C29.8605 25.2773 29.8605 23.2391 32.0347 21.9721C34.2089 20.7051 37.7232 20.7051 39.8837 21.9721C42.0579 23.2391 42.0579 25.2773 39.8837 26.5443Z" fill="#1A8E48" />
        <path d="M79.3202 50.8238C77.146 52.0908 73.6318 52.0908 71.4712 50.8238C69.297 49.5568 69.297 47.5186 71.4712 46.2516C73.6454 44.9846 77.1597 44.9846 79.3202 46.2516C81.4945 47.5186 81.4945 49.5568 79.3202 50.8238Z" fill="#1A8E48" />
        <path d="M75.3958 49.7083C73.8096 49.7083 72.5242 48.4137 72.5242 46.8162V36.5976C72.5242 31.5434 68.8458 25.1258 64.4974 22.6056L43.9723 10.6793C42.4135 9.78413 40.9914 9.53624 40.1572 10.0183C39.3231 10.5003 38.8172 11.8774 38.8172 13.6815V23.9001C38.8172 25.4976 37.5318 26.7922 35.9456 26.7922C34.3594 26.7922 33.074 25.4976 33.074 23.9001V13.6815C33.074 9.72905 34.6055 6.56155 37.272 5.01912C39.9384 3.46292 43.4254 3.71081 46.8303 5.68017L67.3553 17.6065C73.4677 21.1596 78.2537 29.5052 78.2537 36.6114V46.83C78.2537 48.4275 76.9683 49.7221 75.3821 49.7221L75.3958 49.7083Z" fill="url(#paint7_linear_594_102922)" />
        <defs>
            <linearGradient id="paint0_linear_594_102922" x1="112.754" y1="123.139" x2="67.574" y2="123.139" gradientUnits="userSpaceOnUse">
                <stop stopColor="#24C8BA" />
                <stop offset="1" stopColor="#2FFF94" />
            </linearGradient>
            <linearGradient id="paint1_linear_594_102922" x1="17.8408" y1="53.6884" x2="55.6775" y2="53.6884" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFAD4" />
                <stop offset="1" stopColor="#2FC687" />
            </linearGradient>
            <linearGradient id="paint2_linear_594_102922" x1="33.9491" y1="92.3593" x2="33.9491" y2="17.7029" gradientUnits="userSpaceOnUse">
                <stop stopColor="#24C8B5" />
                <stop offset="1" stopColor="#2FFFA8" />
            </linearGradient>
            <linearGradient id="paint3_linear_594_102922" x1="56.3748" y1="76.9211" x2="94.2114" y2="76.9211" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFA93" />
                <stop offset="1" stopColor="#2FC6B4" />
            </linearGradient>
            <linearGradient id="paint4_linear_594_102922" x1="72.4694" y1="115.592" x2="72.4694" y2="40.9356" gradientUnits="userSpaceOnUse">
                <stop stopColor="#24C8BA" />
                <stop offset="1" stopColor="#2FFFAC" />
            </linearGradient>
            <linearGradient id="paint5_linear_594_102922" x1="94.9087" y1="100.168" x2="132.745" y2="100.168" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFA93" />
                <stop offset="1" stopColor="#2FC6B4" />
            </linearGradient>
            <linearGradient id="paint6_linear_594_102922" x1="111.003" y1="138.839" x2="111.003" y2="64.1823" gradientUnits="userSpaceOnUse">
                <stop stopColor="#24C8AD" />
                <stop offset="1" stopColor="#2FFFA8" />
            </linearGradient>
            <linearGradient id="paint7_linear_594_102922" x1="33.0876" y1="26.861" x2="78.2674" y2="26.861" gradientUnits="userSpaceOnUse">
                <stop stopColor="#24C8B5" />
                <stop offset="1" stopColor="#2FFF94" />
            </linearGradient>
        </defs>
    </svg>;
