export const Lendfi: React.FC = () =>
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_595_102983)">
            <path d="M50.279 86.694C49.9462 86.694 49.6133 86.6522 49.2805 86.5548C47.3943 85.998 46.3126 84.0213 46.8673 82.1282L58.3506 42.6928C58.9054 40.7996 60.8747 39.7139 62.7609 40.2707C64.647 40.8275 65.7288 42.8041 65.174 44.6972L53.6907 84.1327C53.233 85.6917 51.8184 86.694 50.279 86.694Z" fill="url(#paint0_linear_595_102983)" />
            <path d="M93.0917 91.6634C91.9822 91.6634 90.8865 91.1345 90.1931 90.1601L58.8775 45.7552C57.7403 44.1404 58.1286 41.9272 59.7235 40.7857C61.3323 39.6582 63.5374 40.034 64.6746 41.6348L95.9902 86.0397C97.1275 87.6545 96.7391 89.8677 95.1442 91.0092C94.5201 91.4546 93.799 91.6634 93.0917 91.6634Z" fill="url(#paint1_linear_595_102983)" />
            <path d="M57.6571 103.788C56.1731 103.788 54.8001 102.855 54.3009 101.38C53.6629 99.5143 54.6615 97.4959 56.5199 96.8556L91.9406 84.7313C93.799 84.0909 95.8239 85.0932 96.4479 86.9585C97.0859 88.8238 96.0874 90.8422 94.2289 91.4825L58.8082 103.607C58.4338 103.732 58.0454 103.802 57.6571 103.802V103.788Z" fill="url(#paint2_linear_595_102983)" />
            <path d="M71.0682 83.5341L70.9018 143.265C70.9018 144.768 70.3748 145.84 69.501 146.341L65.5068 148.68C66.3667 148.165 66.8937 147.107 66.9076 145.604L67.074 85.8727C67.074 82.8103 64.9382 79.0936 62.2754 77.5624L36.965 62.8907C35.6336 62.1251 34.427 62.0555 33.5671 62.5706L37.5613 60.232C38.4351 59.7169 39.6416 59.8005 40.973 60.58L66.2557 75.2239C68.9047 76.769 71.0543 80.4717 71.0543 83.5341H71.0682Z" fill="url(#paint3_linear_595_102983)" />
            <path d="M62.2754 77.5625C64.9244 79.0937 67.074 82.8242 67.0602 85.8727L66.8937 145.604C66.8937 148.652 64.7302 149.877 62.0674 148.346L36.7708 133.688C34.1219 132.143 32 128.426 32 125.378L32.1664 65.6469C32.1664 62.5845 34.3299 61.3596 36.9789 62.8908L62.2754 77.5486V77.5625Z" fill="url(#paint4_linear_595_102983)" />
            <path d="M50.279 97.5377L36.438 89.5058V73.47L50.279 81.4879V97.5377Z" fill="#1A8E48" />
            <path d="M60.8747 112.794L36.438 98.6374V94.8651L60.8747 109.022V112.794Z" fill="#1A8E48" />
            <path d="M60.8747 120.325L36.438 106.168V102.396L60.8747 116.552V120.325Z" fill="#1A8E48" />
            <path d="M60.8747 127.856L36.438 113.699V109.927L60.8747 124.083V127.856Z" fill="#1A8E48" />
            <path d="M118 57.4201L117.847 111.138C117.847 112.488 117.376 113.448 116.585 113.908L112.993 116.01C113.756 115.55 114.242 114.59 114.255 113.239L114.408 59.5221C114.408 56.7659 112.48 53.4251 110.095 52.047L87.3363 38.8508C86.1436 38.1687 85.0479 38.0991 84.2852 38.5585L87.8772 36.4566C88.6677 35.9972 89.7494 36.0668 90.9421 36.7628L113.687 49.9451C116.072 51.3371 118 54.664 118 57.4201Z" fill="url(#paint5_linear_595_102983)" />
            <path d="M110.095 52.047C112.48 53.4251 114.408 56.7798 114.408 59.5221L114.256 113.239C114.256 115.982 112.3 117.081 109.915 115.703L87.1699 102.521C84.7845 101.143 82.8706 97.7882 82.8706 95.046L83.0232 41.3286C83.0232 38.5724 84.9648 37.4727 87.3502 38.8508L110.095 52.0331V52.047Z" fill="#3CE4B7" />
            <path d="M99.3049 70.0038L86.8508 62.7932V48.3582L99.3049 55.5826V70.0038Z" fill="#1A8E48" />
            <path d="M108.833 83.7428L86.8508 71.006V67.6095L108.833 80.3463V83.7428Z" fill="#1A8E48" />
            <path d="M108.833 90.5079L86.8508 77.7711V74.3746L108.833 87.1115V90.5079Z" fill="#1A8E48" />
            <path d="M108.833 97.2731L86.8508 84.5502V81.1537L108.833 93.8905V97.2731Z" fill="#1A8E48" />
            <path d="M68.3221 15.2006L68.2112 53.606C68.2112 54.5665 67.8644 55.2625 67.3097 55.5826L64.744 57.086C65.2987 56.7519 65.6316 56.0698 65.6455 55.1093L65.7564 16.704C65.7564 14.7273 64.3834 12.347 62.6775 11.3587L46.3957 1.92091C45.5358 1.4337 44.7591 1.37802 44.2183 1.71211L46.784 0.208742C47.3387 -0.125339 48.1292 -0.0696587 48.9752 0.431462L65.2294 9.85532C66.9352 10.8436 68.3082 13.2379 68.3082 15.2006H68.3221Z" fill="url(#paint6_linear_595_102983)" />
            <path d="M62.6637 11.3586C64.3696 12.347 65.7426 14.7412 65.7426 16.7039L65.6317 55.1093C65.6317 57.072 64.2309 57.8655 62.5389 56.8771L46.2709 47.4533C44.5651 46.465 43.192 44.0707 43.2059 42.108L43.3169 3.70262C43.3169 1.7399 44.7037 0.946458 46.4096 1.93478L62.6776 11.3586H62.6637Z" fill="url(#paint7_linear_595_102983)" />
            <path d="M54.9528 24.1929L46.0491 19.0425V8.72778L54.9528 13.8782V24.1929Z" fill="#1A8E48" />
            <path d="M61.7623 34.0204L46.0491 24.9167V22.4807L61.7623 31.5844V34.0204Z" fill="#1A8E48" />
            <path d="M61.7623 38.8507L46.0491 29.747V27.325L61.7623 36.4287V38.8507Z" fill="#1A8E48" />
            <path d="M61.7623 43.6948L46.0491 34.5912V32.1691L61.7623 41.2728V43.6948Z" fill="#1A8E48" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_595_102983" x1="46.7286" y1="63.4197" x2="65.3127" y2="63.4197" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFAD8" />
                <stop offset="1" stopColor="#2F9EC6" />
            </linearGradient>
            <linearGradient id="paint1_linear_595_102983" x1="58.2118" y1="65.8975" x2="96.6282" y2="65.8975" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFACD" />
                <stop offset="1" stopColor="#2FAFC6" />
            </linearGradient>
            <linearGradient id="paint2_linear_595_102983" x1="54.1067" y1="94.169" x2="96.6282" y2="94.169" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFA99" />
                <stop offset="1" stopColor="#2FC6C6" />
            </linearGradient>
            <linearGradient id="paint3_linear_595_102983" x1="33.581" y1="104.289" x2="71.0682" y2="104.289" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFACD" />
                <stop offset="1" stopColor="#2FC69E" />
            </linearGradient>
            <linearGradient id="paint4_linear_595_102983" x1="32" y1="105.625" x2="67.0602" y2="105.625" gradientUnits="userSpaceOnUse">
                <stop stopColor="#56FFB3" />
                <stop offset="1" stopColor="#00B7AB" />
            </linearGradient>
            <linearGradient id="paint5_linear_595_102983" x1="84.273" y1="76.0806" x2="117.988" y2="76.0806" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFA93" />
                <stop offset="1" stopColor="#2FC6B4" />
            </linearGradient>
            <linearGradient id="paint6_linear_595_102983" x1="44.2096" y1="28.5373" x2="68.3134" y2="28.5373" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BFA93" />
                <stop offset="1" stopColor="#00AC98" />
            </linearGradient>
            <linearGradient id="paint7_linear_595_102983" x1="54.4673" y1="57.2947" x2="54.4673" y2="1.50326" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4AE1BB" />
                <stop offset="1" stopColor="#32DACF" />
            </linearGradient>
            <clipPath id="clip0_595_102983">
                <rect width="86" height="149" fill="white" transform="translate(32)" />
            </clipPath>
        </defs>
    </svg>;
