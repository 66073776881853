import './index.scss';

export type Variant = 'gradient' | 'bordered' | 'transparent';

type ButtonProps = {
    label?: string;
    link?: string;
    isDisabled?: boolean;
    variant?: Variant;
    children?: JSX.Element;
    className?: string;
    isComingSoon?: boolean;
    isTargetBlank?: boolean;
    image?: string;
};

export const LinkButton: React.FC<ButtonProps> = ({
    label = '',
    link = '',
    isDisabled = false,
    variant = 'gradient',
    children = null,
    className = '',
    isComingSoon = false,
    isTargetBlank = false,
    image = '',
}) =>
    <a
        className={`link-button ${variant} ${className} ${isDisabled || isComingSoon ? 'disabled' : ''}`}
        href={link}
        target={isTargetBlank ? '_blank' : ''}
        aria-label={label}
    >
        {children}
        {label && <span className="link-button__label">{label}</span>}
        {isComingSoon && <span className="link-button__coming-soon">coming soon</span>}
        {image && <img src={image} />}
    </a>;
