import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Landing from '@app/routes/Landing';
import HowToUse from '@app/routes/HowToUse';
import Faq from '@app/routes/Faq';
import NotFound from '@app/routes/NotFoundPage';

const router = createBrowserRouter([
    { path: '/', element: <Landing /> },
    { path: '/how-to-use', element: <HowToUse /> },
    { path: '/faq', element: <Faq /> },
    { path: '*', element: <NotFound /> },
]);

const App = () => <RouterProvider router={router} />;

export default App;
