import './index.scss';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import step1 from '@static/images/howToUse/step-1.png';
import step2 from '@static/images/howToUse/step-2.png';
import step3 from '@static/images/howToUse/step-3.png';
import step4 from '@static/images/howToUse/step-4.png';
import step5 from '@static/images/howToUse/step-5.png';
import step6 from '@static/images/howToUse/step-6.png';
import step7 from '@static/images/howToUse/step-7.png';
import step8 from '@static/images/howToUse/step-8.png';
import step9 from '@static/images/howToUse/step-9.png';
import step10 from '@static/images/howToUse/step-10.png';
import step11 from '@static/images/howToUse/step-11.png';
import step12 from '@static/images/howToUse/step-12.png';
import step13 from '@static/images/howToUse/step-13.png';
import step14 from '@static/images/howToUse/step-14.png';

export const Guide: React.FC = () =>
    <section className="guide">
        <div className="guide__wrapper">
            <h1 className="guide__main-title">
                How to use the Tricorn Bridge
            </h1>
            <p className="guide__description">
                Before using cross-chain transfers to the fullest, you need
                to set up MetaMask and Concordium wallet extensions. For a
                quick start, follow the instructions from the official video
                tutorials:
            </p>
            <div className="guide__link-wrapper">
                <a
                    href="https://youtu.be/GNPz-Dv5BjM?feature=shared"
                    target="_blank"
                    className="guide__link"
                >
                    Setting up MetaMask
                </a>
                <a
                    href="https://youtu.be/aHLojlNU27s?feature=shared"
                    target="_blank"
                    className="guide__link"
                >
                    Setting up Concordium
                </a>
            </div>
            <p className="guide__description">
                Once you’re all done, head over to{' '}
                <a
                    className="guide__description guide__link "
                    href="https://bridge.tricorn.network/"
                    target="blank"
                >
                    Tricorn bridge
                </a>
                , agree to the disclaimer, and proceed with the steps below.
            </p>

            <h2 className="guide__title">Connecting wallets</h2>
            <div className="guide__row">
                <div className="guide__row__example">
                    <p className="guide__description">
                        Click either of the{' '}
                        <span className="guide__marked-text">
                            Connect Wallet
                        </span>{' '}
                        buttons.
                    </p>
                </div>
                <div className="guide__row__example">
                    <p className="guide__description">
                        You’ll be prompted to select the first wallet you’d
                        like to connect.
                    </p>
                </div>
            </div>

            <div className="guide__row">
                <div className="guide__row__example">
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__image__step1"
                            src={step1}
                            alt="instruction image"
                        />
                    </Zoom>
                </div>
                <div className="guide__row__example">
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__image__step2"
                            src={step2}
                            alt="instruction image"
                        />
                    </Zoom>
                </div>
            </div>

            <p className="guide__description">
                After connecting the first wallet, click on the wallet
                address at the top right corner and connect the second
                wallet.
            </p>

            <h2 className="guide__title">Initiating asset transfer</h2>
            <div className="guide__row">
                <div className="guide__row__example">
                    <p className="guide__description">
                        Choose the sender chain (2) and the token you want
                        to transfer. Then choose the destination chain (4)
                        and specify the token amount (5). Enter the
                        destination wallet address (6) – or select the{' '}
                        <span className="guide__marked-text">
                            Send to your wallet
                        </span>{' '}
                        checkbox to autofill this field with the connected
                        wallet.
                    </p>
                    <p className="guide__description">
                        In the estimation section, you can check the
                        approximate information about the upcoming
                        transaction:
                    </p>
                    <ul className="guide__list">
                        <li className="guide__description guide__list__punkt">
                            ‘You will receive’ → The number of tokens you’ll
                            receive based on the current bridge rate and
                            fees;
                        </li>
                        <li className="guide__description guide__list__punkt">
                            ‘Estimated gas fee’ → What you’ll pay for
                            sending your transfer to the destination chain.{' '}
                            <span className="guide__marked-text">
                                Note:
                            </span>{' '}
                            The gas fee is charged in the tokens you send,
                            the number of which is equivalent to the gas fee
                            of the destination network and is calculated
                            using the up-to-date exchange rate at the time
                            of transfer. <br />
                            So, if CCD costs ~$0.005 and the gas fee on
                            Ethereum is ~$5.6, the gas fee for making a
                            transfer from Concordium to Ethereum would be
                            $5.6/$0.005 = 1122.56 CCD. The gas fee may
                            change based on network activity at the time of
                            the transfer;{' '}
                        </li>
                        <li className="guide__description guide__list__punkt">
                            ‘Commission’ → The amount of tokens you’ll pay
                            for Tricorn Bridge services. The commission
                            percentage is always stable.
                        </li>
                    </ul>
                    <p className="guide__description">
                        To confirm the transaction, simply click{' '}
                        <span className="guide__marked-text">Transfer</span>{' '}
                        and sign the transaction in your wallet.
                    </p>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image"
                            src={step3}
                            alt="instruction image"
                        />
                    </Zoom>
                </div>

                <div className="guide__row__example guide__row__example__between">
                    <div>
                        <Zoom>
                            <img
                                loading="lazy"
                                className="guide__image guide__image__step4"
                                src={step4}
                                alt="instruction image"
                            />
                        </Zoom>

                        <p className="guide__description">
                            Once you see the{' '}
                            <span className="guide__marked-text">
                                Transaction in progress{' '}
                            </span>
                            pop-up, all of the actual information about the
                            transaction will appear. To initiate a new
                            transaction, click{' '}
                            <span className="guide__marked-text">
                                Another Transaction
                            </span>
                        </p>
                    </div>

                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image"
                            src={step14}
                            alt="instruction image"
                        />
                    </Zoom>
                </div>
            </div>

            <h2 className="guide__title">Checking transactions history</h2>
            <p className="guide__description">
                To access the full list of transactions or check their
                status, you can either click{' '}
                <span className="guide__marked-text">
                    Transactions history
                </span>{' '}
                in the
                <span className="guide__marked-text">
                    Transaction in progress
                </span>{' '}
                window or click{' '}
                <span className="guide__marked-text">History</span> in the
                header menu.
            </p>
            <Zoom>
                <img
                    loading="lazy"
                    className="guide__image"
                    src={step5}
                    alt="instruction image"
                />
            </Zoom>

            <h2 className="guide__title">
                Switching between wallet accounts
            </h2>
            <p className="guide__description">
                If you want to use the Tricorn Bridge with more than just
                one wallet account, it’s quite easy to do.
            </p>

            <div className="guide__row">
                <div className="guide__row__example">
                    <h3 className="guide__row__example__title">
                        To connect a new MetaMask account:
                    </h3>
                    <div className="guide__description">
                        Disconnect your wallet
                    </div>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step6}
                            alt="instruction image"
                        />
                    </Zoom>

                    <div className="guide__description">
                        Change the account in the browser extension
                    </div>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step7}
                            alt="instruction image"
                        />
                    </Zoom>

                    <p className="guide__description">
                        Connect the account to Tricorn Bridge.
                    </p>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step8}
                            alt="instruction image"
                        />
                    </Zoom>

                    <p className="guide__description">
                        Connect the wallet to Tricorn Bridge.
                    </p>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step9}
                            alt="instruction image"
                        />
                    </Zoom>
                </div>

                <div className="guide__row__example">
                    <h3 className="guide__row__example__title">
                        To connect a new Concordium account:
                    </h3>
                    <div className="guide__description">
                        Disconnect your wallet
                    </div>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step10}
                            alt="instruction image"
                        />
                    </Zoom>

                    <div className="guide__description">
                        Change the account in the browser extension
                    </div>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step11}
                            alt="instruction image"
                        />
                    </Zoom>

                    <p className="guide__description">
                        Allowlist the bridge service to the needed account
                    </p>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step12}
                            alt="instruction image"
                        />
                    </Zoom>

                    <div className="guide__description">
                        Connect the wallet to Tricorn Bridge.
                    </div>
                    <Zoom>
                        <img
                            loading="lazy"
                            className="guide__image guide__row__example__image"
                            src={step13}
                            alt="instruction image"
                        />
                    </Zoom>
                </div>
            </div>

            <h2 className="guide__title">
                Now you’re all set! Enjoy seamless, secure, and efficient
                cross-chain transfers with Tricorn Bridge.
            </h2>
        </div>
    </section>;
