import Aos from 'aos';
import { Suspense, lazy, useEffect } from 'react';

import { Home } from '@app/components/Home';
import { Navbar } from '@app/components/Navbar';
import { Footer } from '@app/components/Footer';

const LazyVision = lazy(() => import('@app/components/Vision'));
const LazyBackedBy = lazy(() => import('@app/components/BackedBy'));
const LazyCommunity = lazy(() => import('@app/components/Community'));
const LazyEcosystem = lazy(() => import('@app/components/Ecosystem'));
const LazyChains = lazy(() => import('@app/components/Chains'));
const LazySolutions = lazy(() => import('@app/components/Solutions'));
const LazyRoadmap = lazy(() => import('@app/components/Roadmap'));

import 'aos/dist/aos.css';

/** Describes duration aos effects time in milliseconds. */
const DURATION_TIME: number = 1500;

const Landing = () => {
    useEffect(() => {
        Aos.init({
            duration: DURATION_TIME,
        });
    }, []);

    return <Suspense fallback="">
        <Navbar />
        <main className="main">
            <Home />
            <LazyBackedBy />
            <LazySolutions />
            <LazyVision />
            <LazyEcosystem />
            <LazyChains />
            <LazyRoadmap />
            <LazyCommunity />
        </main>
        <Footer />
    </Suspense>;
};

export default Landing;
