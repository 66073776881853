import { LinkButton } from '@app/components/common/LinkButton';

import { Bridge } from '@static/images/home/bridge';
import { Lendfi } from '@static/images/home/lendfi';
import halborn from '@static/images/home/audited/halborn.svg';
import hacken from '@static/images/home/audited/hacken.svg';
import coinspect from '@static/images/home/audited/coinspect.svg';

import './index.scss';

export const Home: React.FC = () =>
    <section className="home" id="about">
        <div className="home__wrapper">
            <div className="home__info">
                <h1 className="home__info__title">Safe and secure cross-chain ecosystem</h1>
                <span className="home__info__description">
                    Developing next-gen DeFi products united by a secure proprietary cross-chain communication protocol
                </span>
            </div>
            <div className="home__products">
                <div className="home__products__item">
                    <Bridge />
                    <div className="home__products__item__content">
                        <h3 className="home__products__item__title">Bridge</h3>
                        <p className="home__products__item__description">A secure, cross-chain bridge with self-hosted nodes of 8 supported networks</p>
                        <LinkButton
                            label="launch bridge"
                            link="https://bridge.tricorn.network/"
                            isTargetBlank={true}
                        />
                    </div>
                </div>
                <div className="home__products__item">
                    <Lendfi />
                    <div className="home__products__item__content">
                        <div className="home__products__item__info">
                            <h3 className="home__products__item__title">Lending</h3>
                            <p className="home__products__item__description">The first lend and borrow protocol on the Partisia Blockchain</p>
                        </div>
                        <LinkButton
                            label="Lending"
                            isComingSoon={true}
                        />
                    </div>
                </div>
            </div>
            <div className="home__audited">
                <span className="home__audited__title">AUDITED BY</span>
                <div className="home__audited__icons-wrapper">
                    <a href="https://github.com/HalbornSecurity/PublicReports/blob/master/Cosmos%20Audits/BoostyLabs_Tricorn_Bridge_Server_Golang_Security_Assessment_Report_Halborn_Final.pdf" target="_blank">
                        <img loading="lazy" className="home__audited__icon" src={halborn} alt="halborn" />
                    </a>
                    <a href="https://www.coinspect.com/blog/soroban-source-code-audit-tricorn/" target="_blank">
                        <img loading="lazy" className="home__audited__icon" src={coinspect} alt="coinspect" />
                    </a>
                    <a href="https://drive.google.com/file/d/1mnDHAGEgdh9agG7oM_4xULVZeA9TpBtN/view" target="_blank">
                        <img loading="lazy" className="home__audited__icon" src={hacken} alt="hacken" />
                    </a>
                </div>
            </div>
        </div>
    </section>;
